let apiUrl = process.env.VUE_APP_API_URL;


class RoomApi {
    getListByFloorPlanId(id){
        var url = apiUrl + `rooms?floorPlanId=${id}&limit=100&page=1`;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });

    }

    getAllByFloorPlan(id) {
        var url = apiUrl + `rooms?floorPlanId=${id}&limit=100000&page=1`;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    getList() {
        var url = apiUrl + 'rooms';
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }

    getListByFloorPlan(id) {
        var url = apiUrl + `rooms?floorPlanId=${id}&limit=100000&page=1`;
        return api.call('get', url)
            .then(({ data }) => {
                return data
            });
    }


    get(id) {
        var url = apiUrl + 'rooms/';
        return api.call('get', url + id)
            .then(({ data }) => {
                return data
            });
    }

    create(data) {
        var url = apiUrl + 'rooms';
        return api.call('post', url, data)
            .then(({ data }) => {
                return data
            });

    }
    update(data, payload) {
        var url = apiUrl + 'rooms/';
        return api.call('patch', url + data._id, payload)
            .then(({ data }) => {
                return data
            });
    }
    delete(id) {
        var url = apiUrl + 'rooms/';
        return api.call('delete', url + id)
            .then(({ data }) => {
                return data
            });
    }

}

export default RoomApi;
